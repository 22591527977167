<div class="faq-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="section-title">
                    <h2>ხშირად დასმული <span>კითხვები</span></h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="faq-accordion">
                    <accordion>
                        <accordion-group heading="ვის შეუძლია დიზაინის შეკვეთა?">
                            <p>შეკვეთა შეუძლია ნებისმიერ ადამიანს, ვისაც სჭირდება სამზერეულოს ინდივიდუალური დიზაინის შემუშავება. ჩვენ ვქმნით დიზაინს თქვენი სამზარეულოს პარამეტრებისა და სურვილების გათვალისწინებით.</p>
                        </accordion-group>
                        <accordion-group heading="შეკვეთიდან რამდენ ხანში მივიღებ სამზარეულოს დიზაინს?">
                            <p>შეკვეთის გაფორმებიდან 2 სამუშაო დღეში მიიღებთ სამზარეულოს დიზაინს.</p>
                        </accordion-group>
                        <accordion-group heading="რა ფორმატით მივიღებ დიზაინს?">
                            <p>თქვენ მიიღებთ სამზარეულოს რენდერებს, გამოყენებული მასალების სიას და 3D მოდელს, რომელსაც მობილური ტელეფონის კამერით პირდაპირ სახლში დაათვალიერებთ, თითქოს თქვენი სამზარეულო უკვე იქ იყოს.</p>
                        </accordion-group>
                        <accordion-group heading="თუ რაიმე არ მომწონს დიზაინში, შესაძლებელია ცვლილებების შეტანა?">
                            <p>სამზარეულოს დიზაინის მიღების შემდეგ თქვენ გექნებათ შესაძლებლობა, რომ შეიტანოთ 3 ცვლილება.</p>
                        </accordion-group>
                        <accordion-group heading="მასალების კალკულაციასაც აკეთებთ?">
                            <p> intera.ge ქმნის სამზარეულოს დიზაინს. ჩვენ არ ვახორციელებთ კალკულაციებს, მაგრამ შეგვიძლია მასალებზე რეკომენდაციის გაწევა თქვენი ბიუჯეტის გათვალისწინებით.</p>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
            <!--div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/FAQ_banner.jpg" alt="orderus-img">
                </div>
            </div-->
        </div>
    </div>
</div>