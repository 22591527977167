<section class="feedback-area ptb-100" style="background-color: #E7ECF9;">
    <div class="container">
        <div class="feedback-slides">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>Intera was a game-changer for me. It made me realize that while theory lays the foundation, it's the real practice that builds the structure for SAT success.”</p>
                        <div class="info">
                            <h3>Anna</h3>
                            <span>Switzerland</span>
                            <img src="assets/img/user1.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>Intera was exactly what I needed in my SAT preparation journey. It provided me with the real practice I craved.”</p>
                        <div class="info">
                            <h3>David</h3>
                            <span>Georgia</span>
                            <img src="assets/img/user2.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“I discovered that real practice is the key to mastering the SAT. This platform provided the practical experience I needed.”</p>
                        <div class="info">
                            <h3>Laura</h3>
                            <span>Italy</span>
                            <img src="assets/img/user3.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>