import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    showMessage = false;
    serverMessage = '';
    successFlag = false;

    constructor(private viewportScroller: ViewportScroller, private http: HttpClient) {}

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

    submit(form){
        console.log("form", form);
        if(form.valid) {
            console.log("form valid");
           
            let data = {
             name:    form.value.name,
             email:   form.value.email,
             phoneNumber:  form.value.number,
             subject: form.value.subject,
             message: form.value.message
            }

             this.http
            .post <any>(environment.PROXY_URL + 'contact', data, {})
            .pipe(catchError(err => {
              return throwError(err);
            })).subscribe(response => {
     
                console.log("contact us response", response);
                if(response.status == 'ok') {
                  this.showMessage = true;
                  this.successFlag = true;
                  this.serverMessage = "თქვენი მესიჯი წარმატებით გაიგზავნა!";
                }
                form.reset();
              },
              err => {
                this.showMessage = true;
                this.successFlag = false;
                this.serverMessage = "There was an error, message not sent!";
              });;

        } 
    }

}