<div id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>ჩვენი სამზარეულოები</h2>
            <h4>დააჭირე "3D-ში ნახვას" და დაათვალიერე!</h4>
        </div>
        <div class="team-slides">
            <owl-carousel-o>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/OurKitchens_1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <div class="container" style="display: flex; justify-content: center; margin-bottom: 20px;">
                                    <span class="app-button">3D-ში ნახვა</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/OurKitchens_2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <div class="container" style="display: flex; justify-content: center; margin-bottom: 20px;">
                                    <span class="app-button">3D-ში ნახვა</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/OurKitchens_3.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <div class="container" style="display: flex; justify-content: center; margin-bottom: 20px;">
                                    <span class="app-button">3D-ში ნახვა</span>
                            </div>
                        </div>
                    </div>
                </ng-template>    
            </owl-carousel-o>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>