
<div id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>ჩვენი სამზარეულოები</h2>
            <h4>დააჭირე "3D-ში ნახვას" და დაათვალიერე!</h4>
        </div>
        <div class="container">
             <div *ngIf="modalHidden" class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="single-team-box">
                        <div class="image">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/OurKitchens_1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <div class="container" style="display: flex; justify-content: center; margin-bottom: 20px;">
                                    <span class="app-button">3D-ში ნახვა</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="single-team-box">
                        <div class="image">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/OurKitchens_2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <div class="container" style="display: flex; justify-content: center; margin-bottom: 20px;">
                                    <span class="app-button">3D-ში ნახვა</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="single-team-box">
                        <div class="image">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/OurKitchens_3.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <div class="container" style="display: flex; justify-content: center; margin-bottom: 20px;">
                                    <span class="app-button" (click)="open3DModal()">3D-ში ნახვა</span>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>

<div *ngIf="!modalHidden" class="modal-class">
    <div class="row justify-content-center">
        <div class="design-modal" [hidden]="modalHidden" id="product3DModal">
            <div class="design-modal-content"  [class.full-screen-mode]="fullscreenMode"
            [ngClass]="fullscreenMode ?  '' : 'flip-2-ver-left-2' ">
           
              <div class="container">
                <div class="row">
                  <div class="col-md-9 col-sm-8">
                    <model-viewer style="width: 100%; height: 77vh;"
                        src="{{modelUrl}}" 
                        poster="{{posterUrl}}" 
                        shadow-intensity="2" ar camera-controls touch-action="pan-y" alt="A 3D model carousel">
                        <button slot="ar-button" style="position: absolute; bottom: 5%; right: 25%; padding: 10px; border-radius: 15px; color: white; background-color: #0c92ca; font-size: 16px;">
                          📸 ოთახში ნახვა
                      </button>
                    </model-viewer>
                  </div>
                </div>
              </div>
           </div>
         </div>   
    </div>
</div>


