<div class="cta-area pb-70">
    <div class="container">
        <div class="cta-content">
            <div class="section-title" style="display: flex;flex-direction: column;align-items: center; margin-bottom: 180px;">
                <h1>შენი სამზარეულოს დიზაინი</h1>
                <p style="font-size: 23px; font-weight: bold; margin-bottom: 0px; margin-top: 20px;">შეგვიკვეთე ახლავე და </p>
                <p style="font-size: 23px; font-weight: bold; margin-bottom: 30px;">მიიღე 50%-იანი ფასდაკლება</p>
                <span (click)="toOrder()" class="app-button" style="padding: 10px 50px; font-size: 22px;">შეკვეთა</span>
            </div>
        </div>
    </div>
</div>