<footer class="footer-area">
    <div class="container">
        <h3>Intera.ge</h3>
        <ul>
            <li><a href="https://www.tiktok.com/@intera.ge" target="_blank" class="fab fa-tiktok"></a></li>
            <li><a href="https://www.facebook.com/profile.php?id=100090481926052" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="https://www.linkedin.com/company/intera-ge/" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="https://www.instagram.com/intera.ge/" target="_blank" class="fab fa-instagram"></a></li>
        </ul>
        <p>Intera.ge 2023 All rights reserved.</p>
    </div>
</footer>

<!--app-demo-sidebar></app-demo-sidebar-->
