import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-kitchen-demo',
    templateUrl: './kitchen-demo.component.html',
    styleUrls: ['./kitchen-demo.component.scss']
})
export class KitchenDemoComponent implements OnInit {

	fullscreenMode = true;
	modalHidden = true;
	modelUrl = 'https://d2if7zbaddaglu.cloudfront.net/files/product_3d_item_63d02fdc508f6e511ebff031_1674589313466_0_1674589313466_0_01674589342848';
	posterUrl = 'https://d2if7zbaddaglu.cloudfront.net/files/product_cover_63d02fdc508f6e511ebff031_1674589317410_0_1674589317410_0_11674589345142';
  

    constructor(private viewportScroller: ViewportScroller) {}

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }


	closeModal() {
		this.modalHidden = true;
	  }
	
	  open3DModal() {
		// this.modalHidden = false;
	  }
}