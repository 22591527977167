import { Component } from '@angular/core';
import { TestsService } from 'src/app/services/tests.service';

@Component({
  selector: 'app-kitchen-design-wizzard',
  templateUrl: './kitchen-design-wizzard.component.html',
  styleUrls: ['./kitchen-design-wizzard.component.scss']
})
export class KitchenDesignWizzardComponent {

  step = 1;
  title = ["აირჩიეთ სამზარეულოს წყობა", "ჩაწერეთ ზომები", "აირჩიეთ მაგიდა", "აირჩიეთ მაცივარი", 
           "აირჩიეთ ზედაპირი", "აირჩიეთ ღუმელი", "აირჩიეთ მიკროტალღური ღუმელი", "აირჩიეთ გამწოვი",
           "აირჩიეთ ნიჟარა", "აირჩიეთ ზედა კარადების წყობა", "აირჩიეთ სასურველი ფერი", "შეკვეთის გაგზავნა"];
  layout = ["", "One Wall", "Two Walls", "Three Walls", "Left Corner", "Right Corner"];
  table = ["", "Integrated", "Isolated", "No Table"];
  fridge = ["", "Built In", "Standard"];
  cookTop = ["", "Gas", "Electric"];
  oven = ["", "Under Top", "In Wall", "No Oven"];
  microwave = ["", "Built In", "No Microwave"];
  hood = ["", "Built In", "Isolated", "No Hood"];
  sink = ["", "Round", "Rectangular", "Rectangular With Drainboard", "Double-Bowl Symmetrical", "Double-Bowl Asymmetrical"];
  upperCabinet = ["", "One Level", "Two Levels"];
  colorAndTextureStyle = ["Cold Color", "Warm Color", "Pastel Color", "Black & White Color", "Light Wood", "Dark Wood", "Marble", "No Texture"];

  question = {};
  layoutIndex = 0;
  tableIndex = 0;
  fridgeIndex = 0;
  cookTopIndex = 0;
  ovenIndex = 0;
  microwaveIndex = 0;
  hoodIndex = 0;
  sinkIndex = 0;
  cabinetIndex = 0;
  textureIndices = [0, 0, 0, 0, 0, 0, 0, 0];
  planIndex = 0;
  sizeA = "";
  sizeB = "";
  sizeC = "";


  showMessage = false;
  serverMessage = '';
  successFlag = false;

  constructor(private service: TestsService){}

  ngOnInit() {
    console.log("service questions:", this.service.questions);
   
    this.question = this.service.questions[1];
   
}


 next() {
   this.step++;
 }
 
 prev() {
   this.step--;
 }

 selectLayout(index) {
   this.layoutIndex = index;
   this.next();
 }

 selectTable(index) {
    this.tableIndex = index;
    this.next();
 }

 selectFridge(index) {
   this.fridgeIndex = index;
   this.next();
 }

 selectCookTop(index) {
   this.cookTopIndex = index;
   this.next();
 }

 selectOven(index){
   this.ovenIndex = index;
   this.next();
 }

 selectMicrowave(index) {
   this.microwaveIndex = index;
   this.next();
 }

 selectHood(index) {
   this.hoodIndex = index;
   this.next();
 }

 selectSink(index) {
   this.sinkIndex = index;
   this.next();
 }

 selectCabinet(index) {
   this.cabinetIndex = index;
   this.next();
 }

 selectTexture(index) {
   this.textureIndices[index] = this.textureIndices[index] == 1 ? 0 : 1;
 }

 selectPlan(index) {
   this.planIndex = index;
 }


 saveForm(contactForm) {
  console.log("save form ", contactForm);
 }

 submit(contactForm) {
  console.log("contactForm", contactForm.form.value);
  console.log("layoutIndex = " + this.layoutIndex +
  "tableIndex = " + this.tableIndex +
  "fridgeIndex = " + this.fridgeIndex +
  "cookTopIndex = " + this.cookTopIndex +
  "ovenIndex = " + this.ovenIndex +
  "microwaveIndex = " + this.microwaveIndex +
  "hoodIndex = " + this.hoodIndex +
  "sinkIndex = " + this.sinkIndex +
  "cabinetIndex = " + this.cabinetIndex +
  "textureIndices = " + this.textureIndices)

  let selectedColors = [];
  for(let colorIndex = 0; colorIndex < this.textureIndices.length; colorIndex++) {
    if(this.textureIndices[colorIndex] == 1) {
      selectedColors.push(this.colorAndTextureStyle[colorIndex]);
    }
  }  

  let order = {
    name: contactForm.form.value.name,
    email: contactForm.form.value.email,
    phoneNumber: contactForm.form.value.number,
    message: contactForm.form.value.message,
    layout: {
      type: this.layout[this.layoutIndex],
      sizeA: this.sizeA,
      sizeB: this.sizeB,
      sizeC: this.sizeC
    },
    table: this.table[this.tableIndex],
    fridge: this.fridge[this.fridgeIndex],
    cooktop: this.cookTop[this.cookTopIndex],
    oven: this.oven[this.ovenIndex],
    microwave: this.microwave[this.microwaveIndex],
    hood: this.hood[this.hoodIndex],
    sink: this.sink[this.sinkIndex],
    upperCabinet: this.upperCabinet[this.cabinetIndex],
    colorStyle: selectedColors
  };

  console.log("order", order);

  this.service.sendOrder(order).subscribe(response => {
    console.log("response", response);
    this.next();
  });

 }

  

}
