<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <a class="navbar-brand" style="z-index: 999; cursor: pointer;" routerLink="/">
            <img src="assets/img/Intera_logo.png" style="width: 95px; height: 22px;">
        </a>
       
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar" style="background-color: #1C3169;"></span>
                <span class="middle-bar" style="background-color: #1C3169;"></span>
                <span class="bottom-bar" style="background-color: #1C3169;"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <!--li class="nav-item"><span class="nav-link" (click)="onClick('home')">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">About</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('team')">Team</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Services</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('work')">Work</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Pricing</span></li-->
                    <li class="nav-item"><span class="nav-link" routerLink="/">მთავარი</span></li>
                <!--li class="nav-item"><span class="nav-link" routerLink="exams">Exams</span></li-->
                <li class="nav-item"><span class="nav-link" routerLink="auth/login">შესვლა</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="auth/register">რეგისტრაცია</span></li>
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <!--li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')">About</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('team')">Team</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('services')">Services</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('work')">Work</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('pricing')">Pricing</span></li-->
                    <li class="nav-item"><span class="nav-link" routerLink="/">მთავარი</span></li>
                <!--li class="nav-item"><span class="nav-link" routerLink="exams">Exams</span></li-->
                <li class="nav-item"><span class="nav-link" routerLink="auth/login">შესვლა</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="auth/register">რეგისტრაცია</span></li>
            </ul>
        </div>
    </div>
</nav>