<div id="howTo" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>შეგვიკვეთე დიზაინი</h2>
            <p>მიიღე შენზე მორგებული სამზარეულოს დიზაინი</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="orderus-services">
                    <div class="orderus-img">
                        <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/OrderUs_1.jpg" alt="orderus-img">
                    </div>
                    <div class="orderus-content">
                        <h1>შეიყვანე ინფორმაცია</h1>
                        <p>აირჩიე სასურველი წყობა, ზომები და ფერები</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="orderus-services">
                    <div class="orderus-img">
                        <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/OrderUs_2.jpg" alt="orderus-img">
                    </div>
                    <div class="orderus-content">
                        <h1>ისარგებლე შეთავაზებით</h1>
                        <p>მიიღე 50%-იანი ფასდაკლება სამზარეულოს დიზაინზე</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="orderus-services">
                    <div class="orderus-img">
                        <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/OrderUs_3.jpg" alt="orderus-img">
                    </div>
                    <div class="orderus-content">
                        <h1>მიიღე დიზაინი</h1>
                        <p>2 დღეში მიიღე სამზარეულოს დიზაინი მასალების სიით</p>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</div>