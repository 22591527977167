<div id="team" class="team-area" style="background-color: white; padding-top: 90px; padding-bottom: 70px;">
    <div class="container">
        <div class="wizard-title" style="margin-top: 30px;">
            <h2>{{title[step -1]}}</h2>
        </div>
        <div class="container">
             <div [hidden]="step != 1" class="row justify-content-center">
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':layoutIndex != 1, 'selected-image':layoutIndex==1}" (click)="selectLayout(1)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Layout_1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':layoutIndex != 2, 'selected-image':layoutIndex==2}" (click)="selectLayout(2)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Layout_2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':layoutIndex != 3, 'selected-image':layoutIndex==3}" (click)="selectLayout(3)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Layout_3.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>    
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':layoutIndex != 4, 'selected-image':layoutIndex==4}" (click)="selectLayout(4)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Layout_4.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':layoutIndex != 5, 'selected-image':layoutIndex==5}" (click)="selectLayout(5)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Layout_5.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>    
             </div>
            <div [hidden]="step != 2" class="container">
                <div class="row">
                    <div>
                        <div class="container-fluid" style="margin-top: 0px; margin-bottom: 30px; margin-right: 50px;">
                            <div class="row" style="justify-content: center;">
                                <div *ngIf="layoutIndex==1" class="col-md-10 col-lg-6">
                                    <div  class="wizzard-item-box">
                                        <div class="image">
                                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Dimensions_1.jpg" alt="team-img">
                                        </div>
                                    </div>
                                </div>  
                                <div *ngIf="layoutIndex==2" class="col-md-10 col-lg-6">
                                    <div class="wizzard-item-box">
                                        <div class="image">
                                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Dimensions_2.jpg" alt="team-img">
                                        </div>
                                    </div>
                                </div>  
                                <div *ngIf="layoutIndex==3"  class="col-md-10 col-lg-6">
                                    <div class="wizzard-item-box">
                                        <div class="image">
                                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Dimensions_3.jpg" alt="team-img">
                                        </div>
                                    </div>
                                </div>  
                                <div *ngIf="layoutIndex==4" class="col-md-10 col-lg-6">
                                    <div class="wizzard-item-box">
                                        <div class="image">
                                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Dimensions_4.jpg" alt="team-img">
                                        </div>
                                    </div>
                                </div>  
                                <div *ngIf="layoutIndex==5" class="col-md-10 col-lg-6">
                                    <div class="wizzard-item-box">
                                        <div class="image">
                                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Dimensions_5.jpg" alt="team-img">
                                        </div>
                                    </div>
                                </div>  
                           </div>
                           <div class="row" style="justify-content: center;">
                                <div class="col-md-6 col-lg-4">
                                    <div class="row justify-content-center">
                                        <div class="col-md-4 size-lable">
                                            <label style="margin-left: 20px;">სიგრძე: </label>
                                        </div>
                                        <div class="col-md-8 size-input">
                                            <input name="width" style="margin-right: 10px;" [(ngModel)]="sizeA" >
                                        </div>
                                        <div *ngIf="layoutIndex!=3" class="col-md-4 size-lable">
                                            <label style="margin-left: 20px;">სიღრმე: </label>
                                        </div>
                                        <div  *ngIf="layoutIndex!=3" class="col-md-8 size-input">
                                            <input width="100" name="depth" style="margin-right:10px;" [(ngModel)]="sizeB">
                                        </div>

                                        <div *ngIf="layoutIndex==3" class="col-md-4 size-lable">
                                            <label style="margin-left: 20px;">სიღრმე1: </label>
                                        </div>
                                        <div  *ngIf="layoutIndex==3" class="col-md-8 size-input">
                                            <input width="100" name="depth" style="margin-right:10px;" [(ngModel)]="sizeB">
                                        </div>
                                        <div *ngIf="layoutIndex==3" class="col-md-4 size-lable">
                                            <label style="margin-left: 20px;">სიღრმე2: </label>
                                        </div>
                                        <div  *ngIf="layoutIndex==3" class="col-md-8 size-input">
                                            <input width="100" name="depth" style="margin-right:10px;" [(ngModel)]="sizeC">
                                        </div>
                                    </div>
                                </div>
                           </div>

                        </div>       
                    </div>
                </div>
            </div>
            <div [hidden]="step != 3" class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':tableIndex != 1, 'selected-image':tableIndex==1}" (click)="selectTable(1)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Table_1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':tableIndex != 2, 'selected-image':tableIndex==2}" (click)="selectTable(2)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Table_2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':tableIndex != 3, 'selected-image':tableIndex==3}" (click)="selectTable(3)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Table_3.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>    
             </div>
             <div [hidden]="step != 4" class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':fridgeIndex != 1, 'selected-image':fridgeIndex==1}" (click)="selectFridge(1)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Fridge_1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':fridgeIndex != 2, 'selected-image':fridgeIndex==2}" (click)="selectFridge(2)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Fridge_2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
             </div>
             <div [hidden]="step != 5" class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':cookTopIndex != 1, 'selected-image':cookTopIndex==1}" (click)="selectCookTop(1)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Appliances_cooktop_1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':cookTopIndex != 2, 'selected-image':cookTopIndex==2}" (click)="selectCookTop(2)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Appliances_cooktop_2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
             </div>
             <div [hidden]="step != 6" class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':ovenIndex != 1, 'selected-image':ovenIndex==1}" (click)="selectOven(1)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Appliances_oven_1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':ovenIndex != 2, 'selected-image':ovenIndex==2}" (click)="selectOven(2)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Appliances_oven_2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':ovenIndex != 3, 'selected-image':ovenIndex==3}" (click)="selectOven(3)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Appliances_oven_3.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
             </div>
             <div [hidden]="step != 7" class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':microwaveIndex != 1, 'selected-image':microwaveIndex==1}" (click)="selectMicrowave(1)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Appliances_micro_1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':microwaveIndex != 2, 'selected-image':microwaveIndex==2}" (click)="selectMicrowave(2)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Appliances_micro_2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>   
             </div>
             <div [hidden]="step != 8" class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':hoodIndex != 1, 'selected-image':hoodIndex==1}" (click)="selectHood(1)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Appliances_hood_1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':hoodIndex != 2, 'selected-image':hoodIndex==2}" (click)="selectHood(2)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Appliances_hood_2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>  
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':hoodIndex != 3, 'selected-image':hoodIndex==3}" (click)="selectHood(3)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Appliances_hood_3.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>    
             </div>
             <div [hidden]="step != 9" class="row justify-content-center">
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':sinkIndex != 1, 'selected-image':sinkIndex==1}" (click)="selectSink(1)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Sink_1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':sinkIndex != 2, 'selected-image':sinkIndex==2}" (click)="selectSink(2)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Sink_2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>  
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':sinkIndex != 3, 'selected-image':sinkIndex==3}" (click)="selectSink(3)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Sink_3.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>    
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':sinkIndex != 4, 'selected-image':sinkIndex==4}" (click)="selectSink(4)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Sink_4.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>  
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':sinkIndex != 5, 'selected-image':sinkIndex==5}" (click)="selectSink(5)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Sink_5.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>    
             </div>
             <div [hidden]="step != 10" class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':cabinetIndex != 1, 'selected-image':cabinetIndex==1}" (click)="selectCabinet(1)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Cabinets_1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':cabinetIndex != 2, 'selected-image':cabinetIndex==2}" (click)="selectCabinet(2)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Cabinets_2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
             </div>
             <div [hidden]="step != 11" class="row justify-content-center">
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':textureIndices[0] != 1, 'selected-image':textureIndices[0] == 1}" (click)="selectTexture(0)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Color_1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':textureIndices[1] != 1, 'selected-image':textureIndices[1] == 1}" (click)="selectTexture(1)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Color_2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':textureIndices[2] != 1, 'selected-image':textureIndices[2] == 1}" (click)="selectTexture(2)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Color_3.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>    
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':textureIndices[3] != 1, 'selected-image':textureIndices[3] == 1}"(click)="selectTexture(3)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Color_4.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':textureIndices[4] != 1, 'selected-image':textureIndices[4] == 1}" (click)="selectTexture(4)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Texture_1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>    
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':textureIndices[5] != 1, 'selected-image':textureIndices[5] == 1}" (click)="selectTexture(5)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Texture_2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>    
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':textureIndices[6] != 1, 'selected-image':textureIndices[6] == 1}" (click)="selectTexture(6)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Texture_3.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>    
                <div class="col-md-4 col-lg-3">
                    <div class="wizzard-item-box">
                        <div [ngClass]="{'image':textureIndices[7] != 1, 'selected-image':textureIndices[7] == 1}" (click)="selectTexture(7)">
                            <img src="https://d2if7zbaddaglu.cloudfront.net/static/intera/Texture_4.jpg" alt="team-img">
                        </div>
                        <div class="content">
                           
                        </div>
                    </div>
                </div>    
             </div>
             <div [hidden]="step != 12" class="row justify-content-center">
                <div>
                    <div class="contact-form">
                        <form #contactForm="ngForm" (ngSubmit)="saveForm(contactForm)">
                            <div class="row">
                                <div *ngIf="showMessage" class="col-lg-12 col-md-12">
                                    <div *ngIf="successFlag">
                                        <h4 style="color: green; margin-bottom: 20px;">{{serverMessage}}</h4>
                                    </div>
                                    <div *ngIf="!successFlag">
                                        <h4 style="color: #f42d3f; margin-bottom: 20px;">{{serverMessage}}</h4>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label>სახელი</label>
                                        <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div *ngIf="name.errors.required">სახელი აუცილებელია.</div>
                                            <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                            <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label>ელ. ფოსტა</label>
                                        <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">ელ. ფოსტა აუცილებელია.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label>ტელ. ნომერი</label>
                                        <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                        <div class="alert alert-danger" *ngIf="number.touched && !number.valid">ტელეფონის ნომერი აუცილებელია.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label>მესიჯი</label>
                                        <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                        <div class="alert alert-danger" *ngIf="message.touched && !name.valid">მესიჯი აუცილებელია.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12" style="display: flex; justify-content: right;">
                                    <span class="app-button" (click)="prev()">წინა</span>
                                    <span class="app-button" (click)="submit(contactForm)">გაგზავნა</span>
                                    <!--button type="submit" class="app-button" style="float: right; border: 2px solid;">შემდეგი</button-->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>  
             </div>
             <div [hidden]="step != 14" class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div [ngClass]="{'pricingTable' : planIndex < 100, 'selected-plan' : planIndex == 1}" (click)="selectPlan(1)">
                        <h3 class="title">საწყისი</h3>
                        <div class="price-value">
                            <span class="amount">99.00</span>
                            <span class="month">ლარი</span>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-8" style="margin-left: 44px;">
                                <ul class="pricing-content plan-content">
                                    <li><i class="fas fa-check"></i> Any Ware Access</li>
                                    <li><i class="fas fa-check"></i> 15 GB Hosting</li>
                                    <li><i class="fas fa-check"></i> 5 Unique Users</li>
                                    <li><i class="fas fa-check"></i> 17 GB Capacity</li>
                                    <li><i class="fas fa-check"></i> Weekly Backups</li>
                                    <li><i class="fas fa-check"></i> Support 24 / hour</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div [ngClass]="{'pricingTable' : planIndex < 100, 'selected-plan' : planIndex == 2}" (click)="selectPlan(2)">
                        <h3 class="title">სტანდარტული</h3>
                        <div class="price-value">
                            <span class="amount">149.00</span>
                            <span class="month">ლარი</span>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-8" style="margin-left: 44px;">
                                <ul class="pricing-content plan-content">
                                    <li><i class="fas fa-check"></i> Any Ware Access</li>
                                    <li><i class="fas fa-check"></i> 15 GB Hosting</li>
                                    <li><i class="fas fa-check"></i> 5 Unique Users</li>
                                    <li><i class="fas fa-check"></i> 17 GB Capacity</li>
                                    <li><i class="fas fa-check"></i> Weekly Backups</li>
                                    <li><i class="fas fa-check"></i> Support 24 / hour</li>
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div [ngClass]="{'pricingTable' : planIndex < 100, 'selected-plan' : planIndex == 3}" (click)="selectPlan(3)">    
                        <h3 class="title">პრემიუმი</h3>
                        <div class="price-value">
                            <span class="amount">299.00</span>
                            <span class="month">ლარი</span>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-8" style="margin-left: 44px;">
                                <ul class="pricing-content plan-content">
                                    <li><i class="fas fa-check"></i> Any Ware Access</li>
                                    <li><i class="fas fa-check"></i> 15 GB Hosting</li>
                                    <li><i class="fas fa-check"></i> 5 Unique Users</li>
                                    <li><i class="fas fa-check"></i> 17 GB Capacity</li>
                                    <li><i class="fas fa-check"></i> Weekly Backups</li>
                                    <li><i class="fas fa-check"></i> Support 24 / hour</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [hidden]="step != 13">

                <div class="subscribe-area ptb-100">
                    <div class="container">
                        <div class="newsletter">
                            <h2 style="color: green;">თქვენი შეკვეთა წარმატებით გაიგზავნა!</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="step < 13" class="container">
            <div *ngIf="step != 12" class="wizard-next-section">
                <span *ngIf="step > 1 && step != 13" class="app-button" (click)="prev()">წინა</span>
                <span *ngIf="step < 12" class="app-button" (click)="next()">შემდეგი</span>
                <!--span *ngIf="step == 12" class="app-button" (click)="submit()">გაგზავნა</span-->
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>