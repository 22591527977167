<div class="main-banner item-bg-three ripple-effect jquery-ripples">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text text-start">
                    <div *ngIf="isLargeScreen">
                        <h1>შენი</h1>
                        <h1>სამზარეულოს</h1>
                        <h1>დიზაინერი</h1>
                        <p style="margin-top: 30px;">შეგვიკვეთე სამზარეულოს დიზაინი</p>
                        <p style="margin-bottom: 30px;">და კამერით დაათვალიერე სახლში</p>
                        <span (click)="toOrder()" class="app-button">შეკვეთა</span>
                        <u (click)="onClick('howTo')" class="how-to-link">როგორ მუშაობს ?</u>
                    </div>
                </div>
            </div>
        </div>
    </div>  
</div>

<div *ngIf="!isLargeScreen" style="display: flex; margin-top: 20px; align-items: center; justify-content: center;">
    <span (click)="toOrder()" class="app-button">შეკვეთა</span>
        <u (click)="onClick('howTo')" class="how-to-link">როგორ მუშაობს ?</u>
</div>
   
<app-kitchen-demo></app-kitchen-demo>
<app-how-to></app-how-to>
<app-cta></app-cta>

<app-contact></app-contact>
<app-faq></app-faq>
<app-footer></app-footer>

