import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ar-viewer',
  templateUrl: './ar-viewer.component.html',
  styleUrls: ['./ar-viewer.component.scss']
})
export class ArViewerComponent implements OnInit {


  @Input() modelUrl = '';
  @Input() posterUrl = '';
  defaultPosterUrl = 'https://d2if7zbaddaglu.cloudfront.net/files/product_3d_item_63d02fdc508f6e511ebff031_1674589313466_0_1674589313466_0_01674589342848';
  defaultModelUrl = 'https://d2if7zbaddaglu.cloudfront.net/files/product_cover_63d02fdc508f6e511ebff031_1674589317410_0_1674589317410_0_11674589345142';
  productId = null;
  


  constructor(private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    console.log("ar-viewer ngOnInit")
    const modelViewer = document.querySelector("model-viewer");
    console.log("ModelViewer", modelViewer);
    console.log("first modelUrl in ar viewer ", this.modelUrl)
      console.log("first posterUrl : ", this.posterUrl)
   
    this.route.params.subscribe((params) => {
      console.log("params", params);
      if(params['productId']) {
        this.productId = params['productId'];
      } else if(params['modelUrl']) {
        this.modelUrl = environment.CDN_IMAGE + params['modelUrl'];
        this.posterUrl = params['posterUrl'] ? environment.CDN_IMAGE + params['posterUrl'] : this.defaultPosterUrl;
      } 


      console.log("modelUrl in ar viewer ", this.modelUrl)
      console.log("posterUrl : ", this.posterUrl)

      this.modelUrl = this.modelUrl ? this.modelUrl : this.defaultModelUrl;
      this.posterUrl = this.posterUrl ? this.posterUrl : this.defaultPosterUrl;
     
    });

  }

}
